import React from "react"
import cx from "classnames"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import { IMG } from "../const"
import {
  TECHNOLOGIES,
  STAGES,
  TEAM,
  hours,
  FEATURE_LIST,
  ARCHITECTURES,
} from "../data/kamsa-page"
import Media from "../components/Media"
import "../assets/styles/commons/_heading-numbers.scss"
import "../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import "../assets/styles/commonsGlossy.scss"
import * as styles from "../assets/styles/kamsa.module.scss"
const ContactForm = loadable(() => import("../components/contact-form"))

const Kamsa = () => (
  <Layout pageTitle="Kamsa">
    {({
      handleOpenConfirmation,
      isOpenConfirmation,
      setConfirmationOption,
    }) => {
      return (
        <>
          <main className={cx(styles.kamsaBlock, "glossy")}>
            <article>
              <div className={styles.heading}>
                <h1>Compensation management software</h1>
                <p>
                  Kamsa helps companies pay employees competitively against the
                  market seamlessly.
                </p>
              </div>
              <div className={styles.technologiesBlock}>
                <ul className={styles.details}>
                  <li>
                    <dl>
                      <dt>Industry:</dt>
                      <dd>HR Tech</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>Country:</dt>
                      <dd>USA</dd>
                    </dl>
                  </li>
                  <li>
                    <dl>
                      <dt>Company:</dt>
                      <dd className={styles.logoKamsa}>
                        <img
                          src={`${IMG.KAMSA}/logo-Kamsa-inverse-green.svg`}
                          alt="Logo Kamsa"
                          width="128"
                          height="45"
                        />
                      </dd>
                    </dl>
                  </li>
                </ul>
                <ul className="common__list__techLogos-rounded">
                  {TECHNOLOGIES.map(technology => (
                    <li key={technology.name}>
                      <figure>
                        <img
                          src={`${IMG.ICONS}/logos/${technology.icon}`}
                          width="32"
                          height="32"
                          alt={technology.name}
                        />
                        <figcaption>{technology.name}</figcaption>
                      </figure>
                    </li>
                  ))}
                </ul>
              </div>

              <div className={styles.headingImage}>
                <div className={styles.picsWrapper}>
                  <picture>
                    <source
                      media="(max-width: 900px)"
                      srcSet={`
                        ${IMG.KAMSA}/mobile-head-1.avif,
                        ${IMG.KAMSA}/mobile-head-1@2x.avif 2x,
                        ${IMG.KAMSA}/mobile-head-1@3x.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(max-width: 900px)"
                      srcSet={`
                        ${IMG.KAMSA}/mobile-head-1.webp,
                        ${IMG.KAMSA}/mobile-head-1@2x.webp 2x,
                        ${IMG.KAMSA}/mobile-head-1@3x.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(max-width: 900px)"
                      srcSet={`
                        ${IMG.KAMSA}/mobile-head-1.png,
                        ${IMG.KAMSA}/mobile-head-1@2x.png 2x,
                        ${IMG.KAMSA}/mobile-head-1@3x.png 3x`}
                    />
                    <source
                      media="(min-width: 901px)"
                      srcSet={`
                        ${IMG.KAMSA}/desktop-head-1.avif,
                        ${IMG.KAMSA}/desktop-head-1@2.avif 2x,
                        ${IMG.KAMSA}/desktop-head-1@3.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      media="(min-width: 901px)"
                      srcSet={`
                        ${IMG.KAMSA}/desktop-head-1.webp,
                        ${IMG.KAMSA}/desktop-head-1@2.webp 2x,
                        ${IMG.KAMSA}/desktop-head-1@3.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      media="(min-width: 901px)"
                      srcSet={`
                        ${IMG.KAMSA}/desktop-head-1.jpg,
                        ${IMG.KAMSA}/desktop-head-1@2.jpg 2x,
                        ${IMG.KAMSA}/desktop-head-1@3.jpg 3x`}
                    />
                    <img
                      src={`${IMG.KAMSA}/desktop-head-1@2.jpg`}
                      alt="Screen"
                      width="1069"
                      height="602"
                      loading="lazy"
                      className={styles.pic_1}
                    />
                  </picture>
                  <picture>
                    <source
                      srcSet={`
                        ${IMG.KAMSA}/mobile-head-2.avif,
                        ${IMG.KAMSA}/mobile-head-2@2x.avif 2x,
                        ${IMG.KAMSA}/mobile-head-2@3x.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`
                        ${IMG.KAMSA}/mobile-head-2.webp,
                        ${IMG.KAMSA}/mobile-head-2@2x.webp 2x,
                        ${IMG.KAMSA}/mobile-head-2@3x.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      srcSet={`
                        ${IMG.KAMSA}/mobile-head-2.png,
                        ${IMG.KAMSA}/mobile-head-2@2x.png 2x,
                        ${IMG.KAMSA}/mobile-head-2@3x.png 3x`}
                    />
                    <img
                      src={`${IMG.KAMSA}/mobile-head-2.png`}
                      alt="Screen"
                      width="174"
                      height="293"
                      loading="lazy"
                      className={styles.pic_2}
                    />
                  </picture>
                </div>
              </div>
              <section className={styles.clientProduct}>
                <h2 className="numerous">
                  <span>Client & Product</span>
                </h2>
                <div className={styles.twoColumns}>
                  <div className={styles.pr}>
                    <p>
                      Before cooperation with LaSoft, the Cultivate People
                      consultants worked directly with B2B clients. They worked
                      with various tools and programs to provide excellent
                      customer service. However, this approach took a lot of
                      time and resources. In addition, it was detrimental to the
                      business scaling. It was a business pain.
                    </p>
                    <picture>
                      <source
                        media="(max-width: 480px)"
                        srcSet={`${IMG.KAMSA}/scheme-mobile.svg`}
                      />
                      <source
                        media="(min-width: 481px)"
                        srcSet={`${IMG.KAMSA}/scheme.svg`}
                      />
                      <img
                        src={`${IMG.KAMSA}/scheme.svg`}
                        alt="Scheme"
                        loading="lazy"
                        width="552"
                        height="256"
                      />
                    </picture>
                  </div>
                  <div className={cx(styles.twoColumns, styles.pl)}>
                    <div>
                      <p className={styles.strong}>
                        Cultivate People is a Management Consulting firm that
                        works in the HR industry, and helps its clients to
                        simplify the annual salary reviews and compensation
                        process. With the help of consultants' expertise,
                        professional tools, and worldwide big data on salaries,
                        every employee of the client can get a fair salary for
                        their work.
                      </p>
                      <p>
                        <em>
                          Kamsa closes the complex gaps in compensation, so
                          clients can focus on running their businesses. Kamsa
                          helps companies pay employees competitively against
                          the market seamlessly.
                        </em>
                      </p>
                    </div>
                    <div>
                      <p className={styles.pl}>
                        Therefore, the founder of Cultivate People, Lola Khan,
                        decided to develop a digital platform that would reduce
                        service delivery time and become a starting point for
                        scaling the company without increasing staff
                        (consultants). The digital platform was called “Kamsa”
                        and LaSoft build it!
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.productChallenge}>
                <div className={styles.twoColumns}>
                  <Media query="(min-width: 900px)">
                    {match =>
                      match ? (
                        <div>
                          <div className={styles.picsWrapper}>
                            <picture>
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/challenge-1.avif,
                                    ${IMG.KAMSA}/challenge-1@2x.avif 2x,
                                    ${IMG.KAMSA}/challenge-1@3x.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/challenge-1.webp,
                                    ${IMG.KAMSA}/challenge-1@2x.webp 2x,
                                    ${IMG.KAMSA}/challenge-1@3x.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/challenge-1.png,
                                    ${IMG.KAMSA}/challenge-1@2x.png 2x,
                                    ${IMG.KAMSA}/challenge-1@3x.png 3x`}
                              />
                              <img
                                className={styles.pic_1}
                                src={`${IMG.KAMSA}/challenge-1.png`}
                                alt="Screen"
                                width="368"
                                height="615"
                                loading="lazy"
                              />
                            </picture>
                            <picture>
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/challenge-2.avif,
                                    ${IMG.KAMSA}/challenge-2@2x.avif 2x,
                                    ${IMG.KAMSA}/challenge-2@3x.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/challenge-2.webp,
                                    ${IMG.KAMSA}/challenge-2@2x.webp 2x,
                                    ${IMG.KAMSA}/challenge-2@3x.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/challenge-2.png,
                                    ${IMG.KAMSA}/challenge-2@2x.png 2x,
                                    ${IMG.KAMSA}/challenge-2@3x.png 3x`}
                              />
                              <img
                                className={styles.pic_2}
                                src={`${IMG.KAMSA}/challenge-2.png`}
                                alt="Screen"
                                width="329"
                                height="290"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                        </div>
                      ) : null
                    }
                  </Media>
                  <div>
                    <h2 className="numerous two">
                      <span>Product Challenge</span>
                    </h2>
                    <p className={styles.strong}>
                      The company's founder contacted us with a request to
                      create a product design. After finishing the design stage
                      of the platform, she decided to continue working with
                      LaSoft in developing the platform.
                    </p>
                    <h4 className={styles.h4}>The main challenge was:</h4>
                    <div className={styles.mobilePicsWrapper}>
                      <picture>
                        <source
                          srcSet={`
                            ${IMG.KAMSA}/challenge-1.avif,
                            ${IMG.KAMSA}/challenge-1@2x.avif 2x,
                            ${IMG.KAMSA}/challenge-1@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`
                            ${IMG.KAMSA}/challenge-1.webp,
                            ${IMG.KAMSA}/challenge-1@2x.webp 2x,
                            ${IMG.KAMSA}/challenge-1@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`
                            ${IMG.KAMSA}/challenge-1.png,
                            ${IMG.KAMSA}/challenge-1@2x.png 2x,
                            ${IMG.KAMSA}/challenge-1@3x.png 3x`}
                        />
                        <img
                          className={styles.pic_1}
                          src={`${IMG.KAMSA}/challenge-1.png`}
                          alt="Screen"
                          width="368"
                          height="615"
                          loading="lazy"
                        />
                      </picture>
                      <picture>
                        <source
                          srcSet={`
                            ${IMG.KAMSA}/challenge-2.avif,
                            ${IMG.KAMSA}/challenge-2@2x.avif 2x,
                            ${IMG.KAMSA}/challenge-2@3x.avif 3x`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`
                            ${IMG.KAMSA}/challenge-2.webp,
                            ${IMG.KAMSA}/challenge-2@2x.webp 2x,
                            ${IMG.KAMSA}/challenge-2@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`
                            ${IMG.KAMSA}/challenge-2.png,
                            ${IMG.KAMSA}/challenge-2@2x.png 2x,
                            ${IMG.KAMSA}/challenge-2@3x.png 3x`}
                        />
                        <img
                          className={styles.pic_2}
                          src={`${IMG.KAMSA}/challenge-2.png`}
                          alt="Screen"
                          width="328"
                          height="289"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <p>
                      To develop custom software with a world-class design, that
                      would give a fair assessment of the employee's work and
                      predict a fair salary based on their performance,
                      evaluation system, and big data on salaries worldwide.
                    </p>
                  </div>
                </div>
                <div className={styles.twoColumns}>
                  <div>
                    <h2 className="numerous three">
                      <span>
                        Co-creation: <br />
                        client + product <br />
                        designers <br />+ developers
                      </span>
                    </h2>
                    <p>
                      During the product development, the team worked closely
                      with the client. This collaboration at an early stage
                      increased success in the late development phase and made a
                      unique UX and UI design of the platform.
                    </p>
                  </div>
                  <div className={styles.stages}>
                    <h4 className={styles.h4}>
                      We tested hypotheses in 3 stages:
                    </h4>
                    <div className={styles.stagesWrapper}>
                      {STAGES.map(item => (
                        <div key={item.id}>
                          <div>{item.name}</div>
                        </div>
                      ))}
                    </div>
                    <h4 className={styles.h4}>As a result</h4>
                    <p>
                      The approach of testing product features significantly
                      reduced the final project budget. The product consists
                      entirely of usable features only.
                    </p>
                  </div>
                </div>
              </section>
              <section className={styles.job}>
                <h2 className="numerous four">
                  <span>The job was done</span>
                </h2>
                <p>
                  Our highly productive team created and developed the product
                  from scratch to an all-in-one strategic compensation
                  management platform for base salary, total cash, and equity.
                </p>
                <div className={styles.membersContainer}>
                  {TEAM.map(item => (
                    <div key={item.id} className={item.className}>
                      <picture className={styles.imgWrapper}>
                        <source
                          srcSet={`${IMG.KAMSA}/team/${item.image}.avif`}
                          type="image/avif"
                        />
                        <source
                          srcSet={`${IMG.KAMSA}/team/${item.image}.webp`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.KAMSA}/team/${item.image}.jpg`}
                        />
                        <img
                          src={`${IMG.KAMSA}/team/${item.image}.jpg`}
                          alt={item.name}
                          loading="lazy"
                          width="32"
                          height="32"
                        />
                      </picture>
                      {item.name}
                    </div>
                  ))}
                  <div className={styles.membersHours}>
                    {hours.map(hour => (
                      <div key={hour}>{hour}</div>
                    ))}
                  </div>
                </div>
              </section>
              <section className={styles.features}>
                <h2 className="numerous five">
                  <span>Implemented features</span>
                </h2>
                <p>
                  We have developed the main functionality into one application.
                </p>
                <div className={styles.featuresList}>
                  {FEATURE_LIST.map(item => (
                    <div key={item.id}>
                      <div className={styles.textBlock}>
                        <h3>{item.title}</h3>
                        {item.text}
                      </div>
                      <div
                        className={styles.imageBlock}
                        id={`features-${item.id}-block`}
                      >
                        <div
                          className={styles.imageWrapper}
                          id={`features-${item.id}`}
                        >
                          {item.screenshots.map((point, index) => (
                            <picture
                              key={index}
                              id={`features-${item.id}-${point.name}`}
                            >
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}.avif,
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}@2x.avif 2x,
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}@3x.avif 3x`}
                                type="image/avif"
                              />
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}.webp,
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}@2x.webp 2x,
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}@3x.webp 3x`}
                                type="image/webp"
                              />
                              <source
                                srcSet={`
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}.png,
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}@2x.png 2x,
                                    ${IMG.KAMSA}/features-${item.id}-${point.name}@3x.png 3x`}
                              />
                              <img
                                src={`${IMG.KAMSA}/features-${item.id}-${point.name}@2x.png`}
                                alt={item.title}
                                width={point.width}
                                height={point.height}
                                loading="lazy"
                              />
                            </picture>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
              <section className={styles.architecture}>
                <h2 className="numerous six">
                  <span>App Architecture</span>
                </h2>
                <p>
                  The custom software development started in 2019 with several
                  small and significant updates. Therefore, we have been
                  updating and improving the software architecture to ensure it
                  best achieves the client's goals.
                </p>
                <div className={styles.architectureTypes}>
                  {ARCHITECTURES.map(item => (
                    <div key={item.id} className={styles.architectureCard}>
                      <img
                        src={`${IMG.KAMSA}/${item.image}`}
                        alt={item.title}
                        loading="lazy"
                        width="287"
                        height="204"
                      />
                      <h4>{item.title}</h4>
                      <p>{item.text}</p>
                    </div>
                  ))}
                </div>
              </section>
              <section className={styles.outcomes}>
                <div className={styles.outcomesText}>
                  <div>
                    <h2>Product Development Outcomes</h2>
                    <p className={styles.strong}>
                      Cultivate people continue to be a successful organization
                      that started as a start-up from Google accelerator and, in
                      2022 entered the Inc. 5000 as one of the fastest-growing
                      US companies.
                    </p>
                  </div>
                  <p>
                    With “Kamsa”, companies reduced the time for determining the
                    salary from 1.5 months to 1–2 weeks, and the company
                    “Cultivate People” was able to move to a qualitative way of
                    business scaling.
                  </p>
                </div>
                <div className={styles.outcomesImg}>
                  {/* TODO: improve quote markup using <figure>, <blockquote>, <figcaption>, and schema.org -- karlsson */}
                  <picture>
                    <source
                      srcSet={`${IMG.KAMSA}/matt.avif, ${IMG.KAMSA}/matt@2x.avif 2x,	${IMG.KAMSA}/matt@3x.avif 3x`}
                      type="image/avif"
                    />
                    <source
                      srcSet={`${IMG.KAMSA}/matt.webp, ${IMG.KAMSA}/matt@2x.webp 2x,	${IMG.KAMSA}/matt@3x.webp 3x`}
                      type="image/webp"
                    />
                    <source
                      srcSet={`${IMG.KAMSA}/matt.jpg, ${IMG.KAMSA}/matt@2x.jpg 2x, ${IMG.KAMSA}/matt@3x.jpg 3x`}
                    />
                    <img
                      src={`${IMG.KAMSA}/matt@2x.jpg`}
                      alt="CTO Kamsa Matt Harbes"
                      loading="lazy"
                      width="370"
                      height="510"
                    />
                  </picture>
                  <p>
                    <em className={styles.quotes}>
                      “We’ve worked with LaSoft for several years and are
                      continually impressed. Their passion and dedication to
                      Kamsa is second to none, and they are always looking for
                      new ways to improve and ensure the success of our team. We
                      couldn’t ask for a better partnership.”
                    </em>
                    <span>Matt Harbes, CTO of Kamsa</span>
                  </p>
                </div>
              </section>
            </article>
          </main>
          <section className="footerForm">
            <div>
              <ContactForm
                formStatID="CommonFooter"
                handleOpenConfirmation={handleOpenConfirmation}
                isOpenConfirmation={isOpenConfirmation}
                setConfirmationOption={setConfirmationOption}
              />
            </div>
          </section>
        </>
      )
    }}
  </Layout>
)

export default Kamsa
